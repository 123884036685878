<template>
  <section class="l-content">
    <el-card>
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业">
          <l-select v-model="condition.enterpriseCode" :remote="$api.enterpriseList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="车牌号/车架号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="enterpriseName" slot-scope="scope">
          <el-button type="text" @click="$site.view_enterprise_detail(scope.row.enterpriseCode)">{{ scope.row.enterpriseName }}</el-button>
        </template>
        <template slot="carNo" slot-scope="scope">
          <el-button type="text" @click="$site.view_car_detail(scope.row.carNo)">{{ scope.row.carNo }}</el-button>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarList',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '所属企业', slot: 'enterpriseName' },
          { label: '车牌号', slot: 'carNo' },
          { label: '品牌/车系', prop: 'carBrandInfo', filter: (a, data) => (a ? a.brandName + '-' + data.carSeriesInfo.seriesName : '') },
          { label: 'VIN码', prop: 'vin' },
          { label: '使用性质', prop: 'useCharacter' },
          { label: '发动机号', prop: 'engineNo' },
          { label: '注册日期', prop: 'registerDate', filter: (a, data) => this.$lgh.limitTo(a, 10) }
        ]
      },
      condition: {
        keyword: '',
        enterpriseCode: '',
        isPlatform: true,
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/car/list', this.condition)
    }
  }
}
</script>
